import './App.css';
import styled from "styled-components";
import React, { Suspense, useState, useEffect } from 'react';

const Spline = React.lazy(() => import('@splinetool/react-spline'));

export default function App() {
    const [isLoading, setIsLoading] = useState(true); // Track loading state

    useEffect(() => {
        // Simulate loading time (replace with your actual data fetching)
        const timeout = setTimeout(() => setIsLoading(false), 2000); // Adjust timeout as needed

        return () => clearTimeout(timeout); // Cleanup on unmount
    }, []);

    return (
        <Wrapper>
            {isLoading && ( // Conditionally render loading screen
                <SplashScreen>
                    {/* Add your custom loading indicator here (spinner, animation, etc.) */}
                    <div>Loading... (to be prettified)</div>
                </SplashScreen>
            )}
            <Suspense fallback={<div>Loading Spline...</div>}>
                <Spline scene="https://prod.spline.design/v0r7kYZVB4Aa86ZG/scene.splinecode" />
            </Suspense>
        </Wrapper>
    );
}

const Wrapper = styled.div`
    font-family: "Rubik", sans-serif;
    font-size: 20px;
    color: white;
    height: 100vh;
    height: 100svh;
    pointer-events: auto;
`;

const SplashScreen = styled.div`
    position: fixed; // Ensure loading screen covers entire viewport
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0c0c0c; // Semi-transparent background
    display: flex;
    justify-content: center;
    align-items: center;
`;
